/* const url = `http://localhost:4000/api/v1`
const userBaseUrl = `http://localhost:4000/api/v1/users` */
const API =
    process.env.NODE_ENV === "production"
    ? "http://104.237.151.150:4000/api/v1"
    : "http://localhost:4000/api/v1";

class pricingDataModel {
  // access all data from API
  static allData = () => {
    // api index route
    return fetch(`${API}/pricing`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
      },
    }).then((res) => res.json());
  };

  static deleteRow = (rowId) => {
    return fetch(`${API}/pricing/${rowId}`, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
      },
    }).then((res) => res.json())
      .then((res) => console.log(res));
  }

  static addRow = (newData) => {
    return fetch(`${API}/pricing/create`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newData)
      
    }).then((res) => res.json())
      .then((res) => console.log(res));
  }

  static updateRow = (newData, rowId) => {
    return fetch(`${API}/pricing/${rowId}`, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newData)
      
    }).then((res) => res.json())
      .then((res) => console.log(res));
  }

  static import = (newData) => {
    return fetch(`${API}/pricing/import_bulk`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newData)
      
    }).then((res) => res.json())
      .then((res) => console.log(res));
  }
}



export default pricingDataModel;