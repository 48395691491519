import React from "react";
import Logout from "../components/Logout/Logout.js"
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import "./Navigation.css"
import { useRecoilValue } from 'recoil';
import { tokenAtom } from "../recoil/atoms"

function Navbar() {
  
  const token = useRecoilValue(tokenAtom);   
  if (token != null) {
  return (
    <AppBar position="static">
      <CssBaseline />
      <Toolbar>
        <Typography variant="h4" className="logo">
          Covenant
        </Typography>
          <div className="nav-links">
            {/* <Link to="/" className={classes.link}>
              Sign In
            </Link> */}
            <Link to="/dashboard" className="link" >
              Dashboard
            </Link>
            <Link to="/rates" className="link">
              Rates
            </Link>
            <Link to="/duplicates" className="link">
              Duplicates
            </Link>
            <Link to="/exec-table" className="link">
              Exec
            </Link>
            <Link to="/import" className="link">
              Import
            </Link>
            <Logout />
          </div>
      </Toolbar>
    </AppBar>
  );
} else {
  return (
    <AppBar position="static">
      <CssBaseline />
      <Toolbar>
        <Typography variant="h4" className="logo">
          Covenant
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
}

export default Navbar;
