import React from 'react';

const Title = () => {
    return (
			<div>
				<h1>Covenant Insurance Group</h1>
			</div>
		);
};

export default Title;