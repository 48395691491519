import { React, useState, useEffect, forwardRef, } from "react";
import MaterialTable from "material-table";
import dataModel from '../models/pricingData'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReactLoading from 'react-loading';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import './Rates.css'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const API =
    process.env.NODE_ENV === "production"
    ? "http://104.237.151.150:4000/api/v1"
    : "http://localhost:4000/api/v1";

const theme = createTheme();

export default function Rates (){
  const [tableData, setTableData] = useState([]);
  const columns = [];
  const [forceFetch, setForceFetch] = useState(false);


  
  function createCol() {
    const keysArr = Object.keys(tableData[0]);
    keysArr.forEach(function (key) {
      if (key == 'id') {
        columns.push({ title: key, field: key, editable: 'never' })
      } else { 
        columns.push({ title: key, field: key, cellStyle: { minWidth: 150 } });
       }
    })
  }
  
  useEffect(function () {
    fetch(`${API}/pricing`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
      },
    })
    .then((response) => response.json())
    .then((response) => setTableData(response.pricing));
  }, [forceFetch]);
  
  function handleDelete(rowId) {
    if (window.confirm("Are you sure you want to delete this row?")){
      dataModel.deleteRow(rowId);
      setTableData(tableData.filter(
        (row) => row.rowId !== rowId))

    }
  }
  function handleAdd(newData) {
      dataModel.addRow(newData);
      setTableData(tableData.concat(newData))
      if (forceFetch === true){
        setForceFetch(false);
      } else (
        setForceFetch(true)
      )
  }

  function handleUpdate(newData,oldId){
    var index = tableData.findIndex(x => x.rowId ===oldId);
    dataModel.updateRow(newData, oldId);
    tableData[index] = newData;
    if (forceFetch === true){
      setForceFetch(false);
    } else (
      setForceFetch(true)
    )
  };




  const adapter = tableData => tableData.map(col => (
    {
        id: col.id,
        createdAt: col.createdAt,
        updatedAt: col.updatedAt,
        carrier: col.carrier,
        naic: col.naic,
        classCode: col.classCode,
        classDescription: col.classDescription,
        effectiveDate: col.effectiveDate,
        manualRate: col.manualRate,
    }
));


  if (tableData.length == 0){
    return(
      <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
      >
      <ReactLoading type={"bars"} color={"#3f51b5"} height={'50%'} width={'30%'} />
      </div>
      )
    } else {
      createCol();

  return (
    <ThemeProvider theme={theme}>
<div className="wrapper">
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        icons={tableIcons}
        title="Rates Table"
        columns={columns}
        data={adapter(tableData)}
        // actions={[
        //   {
        //     icon: DeleteOutline,
        //     tooltip: "Delete Row",
        //     onClick: (event, tableData) =>
        //     handleDelete(tableData.rowId),
        //   },

        // ]}
        options={{
          pageSize:20,
          pageSizeOptions:[20,30,50,100], 
          addRowPosition: "first",
          exportAllData: true,
          filtering: true,
          exportButton: {
            csv: true,
         }
        }}
        defaultSorted={[
          {
            id: "carrier",
            desc: true
          }
        ]}
        // editable={{

        //   onRowAdd: (newData) =>
        //     new Promise((resolve, reject) => {
        //       setTimeout(() => {
        //         handleAdd(newData);
        //         resolve();
        //       }, 1000);
        //     }),

        //     onRowUpdate: (newData, oldData) =>
        //     new Promise((resolve, reject) => {
        //         setTimeout(() => {
        //           let index = oldData.rowId
        //            handleUpdate(newData, index)
        //             resolve();
        //         }, 1000);
        //     }),
        // }}
        
      />
    </div>
</div>
</ThemeProvider>
  );
}};


