import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import csv from "csvtojson";
import dataModel from "../models/recordData";
import dataModelPricing from "../models/pricingData";
import "./Import.css";

const theme = createTheme();

export default function Import() {
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState();
  const [importMessage, setImportMessage] = useState("");

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
    setImportMessage(e.target.files[0].name);
  };

  function handleOnSubmit(event,table) {
    event.preventDefault();

    if (file) {
      const fileReader = new FileReader();
      let csvOutput;
      fileReader.onload = function (event) {
        csvOutput = event.target.result;
        csv()
          .fromString(csvOutput)
          .then((jsonObj) => {
            let chunked = [];
            let size = 3000;
            for (let i = 0; i < jsonObj.length; i += size) {
              chunked.push(jsonObj.slice(i, i + size));
            }
            let idx = 0
            chunked.forEach( async function (chunk, i) {
              setTimeout(() => {
                if(table == 'dashboard') {
                  dataModel.import(chunk);
                }else{
                  dataModelPricing.import(chunk);
                }
                setImportMessage("Loading - Do not refresh or leave this page");
                idx++
                console.log("idx " +idx)
                console.log("length "+chunked.length)
                if(idx == chunked.length){
                  const timeOut = setTimeout(() => {
                    setImportMessage("Import Complete!");
                  },(3000 * chunked.length))
                }
              }, i * 5000);
            });
          })
        };
      fileReader.readAsText(file);
    } else {
      setImportMessage("Please Select a File");
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <h1>CSV File Import</h1>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <form>
          <Box>         
            <Button variant="contained" component="label" sx={{mt: 2}}>
            Choose CSV File
            <input
              type="file"
              id={"csvFileInput"}
              accept={".csv"}
              onChange={handleOnChange}
              hidden
            />
          </Button></Box>
          <Typography component="h1" variant="h5">
          {importMessage && <p className="import_message"> {importMessage} </p>}
        </Typography>
        <Box>
          <Button
            sx={{mt: 2}}
            variant="contained"
            component="label"
            onClick={(e) => {
              handleOnSubmit(e,'dashboard');
            }}
          >
            DASHBOARD - UPLOAD
          </Button>
          </Box>
          <Box>
          <Button
            sx={{m: 2}}
            variant="contained"
            component="label"
            onClick={(e) => {
              handleOnSubmit(e,'rates');
            }}
          >
            RATES - UPLOAD
          </Button>
          </Box>
        </form>
        <Typography component="h1" variant="h5">
          {errorMessage && <p className="login_error"> {errorMessage} </p>}
        </Typography>
       

      </Container>
    </ThemeProvider>
  );
}
