/* const url = `http://localhost:4000/api/v1`
const userBaseUrl = `http://localhost:4000/api/v1/users` */

const API =
    process.env.NODE_ENV === "production"
    ? "http://104.237.151.150:4000/api/v1"
    : "http://localhost:4000/api/v1";



class AuthModel {
  static login = (data) => {
    return fetch(`${API}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  };

  static verify = () => {
    return fetch(`${API}/user/verify`, {
      method: "GET",
      // add header authorization with token
      headers: {
        authorization: `Bearer ${localStorage.token}`,
        // for session based auth
        // withCredientials: true
      },
    }).then((response) => response.json());
  };
      /**
     * Authenticate a user. Save a token string in Local Storage
     *
     * @param {string} token
     */
       static authenticateUser(token) {
        localStorage.setItem('token', token);
      }
  
      /**
       * Check if a user is authenticated - check if a token is saved in Local Storage
       *
       * @returns {boolean}
       */
      static isUserAuthenticated() {
        return localStorage.getItem('token') !== null;
      }
  
      /**
       * Deauthenticate a user. Remove a token from Local Storage.
       *
       */
      static deauthenticateUser() {
        localStorage.removeItem('token');
      }
  
      /**
       * Get a token value.
       *
       * @returns {string}
       */
  
      static getToken() {
        return localStorage.getItem('token');
      }
  
    }



export default AuthModel;
