import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ children}) => {
    const isAuthenticated = localStorage.token
        
    if (isAuthenticated ) {
      return children
    }
      
    return <Navigate to="/" />
  }
    
export default PrivateRoute
