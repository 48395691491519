import React, { useEffect } from 'react'
import Navbar from './Global/Navigation'
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from './pages/LandingPage'
import Dashboard from './pages/Dashboard'
import Rates from './pages/Rates'
import Duplicates from './pages/Duplicates'
import ExecTable from './pages/ExecTable'
import Import from './pages/Import'
import PrivateRoute from './components/PrivateRoute'





function App() {
  return (
    
    <div className="App">
      <Router>
       <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/rates"
            element={
              <PrivateRoute>
                <Rates />
              </PrivateRoute>
            }
          />
           <Route
            path="/duplicates"
            element={
              <PrivateRoute>
                <Duplicates />
              </PrivateRoute>
            }
          />
           <Route
            path="/exec-table"
            element={
              <PrivateRoute>
                <ExecTable />
              </PrivateRoute>
            }
          />
            <Route
            path="/import"
            element={
              <PrivateRoute>
                <Import />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

