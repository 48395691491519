import { React, useState, useEffect, forwardRef, } from "react";
import MaterialTable from "material-table";
import dataModel from '../models/recordData'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReactLoading from 'react-loading';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import './Rates.css'
import { Co2Sharp } from "@mui/icons-material";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};



const API =
    process.env.NODE_ENV === "production"
    ? "http://104.237.151.150:4000/api/v1"
    : "http://localhost:4000/api/v1";


const theme = createTheme();

export default function ExecTable (){
  const [tableData, setTableData] = useState([]);
  const columns = [];
  const [forceFetch, setForceFetch] = useState(false);


  
  function createCol() {
    const keysArr = Object.keys(tableData[0]);
    keysArr.forEach(function (key) {
      if (key != 'cod') {
        columns.push({ title: key, field: key, editable: 'never' })
      } else { 
        columns.push({ title: key, field: key, cellStyle: { minWidth: 150 } });
       }
    })
  }
  
  useEffect(function () {
    fetch(`${API}/records/execTable`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
      },
    } )
    .then((response) => response.json())
    .then((response) => setTableData(response.records));
  }, [forceFetch]);
  
  function handleDelete(deletedRows) {
    let deletedArr = [];
    if (window.confirm("Are you sure you want to delete these rows?")){
      deletedRows.forEach(function (obj) {
        dataModel.deleteRow(obj.rowId);
        deletedArr.push(obj.rowId);
      });

    setTableData(tableData.filter((row) => !deletedArr.includes(row.rowId)))

    if (forceFetch === true){
      setForceFetch(false);
    } else (
      setForceFetch(true)
    )
    }
  }

  //   function handleBulkUpdate(changes){
  //     Object.entries(changes).forEach((change) => {
  //       Object.entries(change[1]).forEach((rowUpdate) => {
  //       let oldId = rowUpdate[1].rowId
  //       let newRow = rowUpdate[1]
  //       var index = tableData.findIndex(x => x.rowId === oldId);
  //       setTableData(tableData.filter(
  //         (row) => row.rowId !== oldId))
  //       dataModel.updateExecRow(newRow, oldId);
  //     })
  //   })
  // };
  function handleBulkUpdate(changes){
    let filterIds = []
    Object.entries(changes).forEach((change) => {
      let oldId = change[1].newData.rowId
      let newRow = change[1].newData
      filterIds.push(oldId)
      dataModel.updateExecRow(newRow, oldId);
  })
  setTableData(tableData.filter(
    (i) => !filterIds.includes(i.rowId)))
};


  // function handleUpdate(newData,oldId){
  //   var index = tableData.findIndex(x => x.rowId ===oldId);
  //   dataModel.updateExecRow(newData, oldId);
  //   tableData[index] = newData;
  //   if (forceFetch === true){
  //     setForceFetch(false);
  //   } else (
  //     setForceFetch(true)
  //   )
  // };

  // function handleUpdateCell (newValue, oldValue, rowData, columnDef) {
  //   let oldId = rowData.rowId
  //   var index = tableData.findIndex(x => x.rowId === oldId);
  //   let column = columnDef.title
  //   Object.keys(rowData).forEach(function(key) {
  //     if (key == column) {
  //       rowData[key] = newValue;
  //     }
  //   })
  //   setTableData(tableData.filter(
  //     (row) => row.rowId !== oldId))
  //   // tableData[index] = rowData;
  //   dataModel.updateExecRow(rowData, oldId);

  //   if (forceFetch === true){
  //     setForceFetch(false);
  //   } else (
  //     setForceFetch(true)
  //   )
  // }

  const adapter = tableData => tableData.map(col => (
    {
        rowId: col.rowId,
        cod: col.cod,
        codDate: col.codDate,
        createdAt: col.createdAt,
        updatedAt: col.updatedAt,
        dliUpdatedAt: col.dliUpdatedAt,
        gccUpdatedAt: col.gccUpdatedAt,
        rateUpdatedAt: col.rateUpdatedAt,
        company: col.company,
        contact: col.contact,
        title: col.title,
        address1: col.address1,
        address2: col.address2,
        city: col.city,
        state: col.state,
        zip: col.zip,
        phone: col.phone,
        tollFree: col.tollFree,
        fax: col.fax,
        companyType: col.companyType,
        employeeCount: col.employeeCount,
        sales: col.sales,
        sic: col.sic,
        naics: col.naics,
        county: col.county,
        source: col.source,
        website: col.website,
        email: col.email,
        contact2: col.contact2,
        title2: col.title2,
        phone2: col.phone2,
        email2: col.email2,
        naicsDescription: col.naicsDescription,
        sqft: col.sqft,
        ranking: col.ranking,
        clientRep: col.clientRep,
        pipelineStatus: col.pipelineStatus,
        lasActivity: col.lasActivity,
        lastActivityDate: col.lastActivityDate,
        customerId: col.customerId,
        callList: col.callList,
        letterSent: col.letterSent,
        carrier: col.carrier,
        renewalMonthOtherIns: col.renewalMonthOtherIns,
        wcMailingFollowUp: col.wcMailingFollowUp,
        renewalMonthWc: col.renewalMonthWc,
        renewalDate: col.renewalDate,
        currentCarrier: col.currentCarrier,
        carrierRate: col.carrierRate,
        nySavingsRate: col.nySavingsRate,
        nyRate: col.nyRate,
        cgg: col.cgg,
        pcrb: col.pcrb,
        fein: col.fein,
        mod: col.mod,
        specialMailMonth: col.specialMailMonth,
        marker: col.marker,
        district: col.district,
        primaryName: col.primaryName,
        anniversaryDate: col.anniversaryDate,
        cancellationDate: col.cancellationDate,
        effectiveDate: col.effectiveDate,
        carrierNAIC: col.carrierNAIC,
        policyNumber: col.policyNumber,
        expirationDate: col.expirationDate,
        
    }
));

function compare( a, b ) {
    if ( a.company < b.company ){
      return -1;
    }
    if ( a.company > b.company ){
      return 1;
    }
    return 0;
  }
  


if (tableData.length == 0){
    return(
        <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
        >
        <ReactLoading type={"bars"} color={"#3f51b5"} height={'50%'} width={'30%'} />
        </div>
      )
    } else {
      createCol();

  return (
    <ThemeProvider theme={theme}>
<div className="wrapper">
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        
        icons={tableIcons}
        title="Exec Table"
        columns={columns}
        data={adapter(tableData.sort(compare))}
        actions={[
          {
            icon: DeleteOutline,
            tooltip: "Delete Row",
            onClick: (event, deletedRows) =>
            handleDelete(deletedRows),
          },
        ]}
        options={{
            pageSize:20,
            addRowPosition: "first",
            sorting: true,
            filtering: true,
            selection: true,
            exportAllData: true,
            actionsColumnIndex: -1,
            exportButton: {
              csv: true,
           }
        }}
        editable={{
          onBulkUpdate: changes =>
            new Promise((resolve, reject) => {
                setTimeout(() => {
                   handleBulkUpdate(changes)
                    resolve();
                }, 1000);
            }),
        }}
        // editable={{
        //     onRowUpdate: (newData, oldData) =>
        //     new Promise((resolve, reject) => {
        //         setTimeout(() => {
        //           let index = oldData.rowId
        //            handleUpdate(newData, index)
        //             resolve();
        //         }, 1000);
        //     }),
        // }}
      //   cellEditable={{
      //     onCellEditApproved: (newValue, oldValue, rowData, columnDef) =>
      //     new Promise((resolve, reject) => {
      //         setTimeout(() => {
      //            handleUpdateCell(newValue, oldValue, rowData, columnDef)
      //             resolve();
      //         }, 1000);
      //     }),
      // }}
      />
    </div>
</div>
</ThemeProvider>
  );
}};


