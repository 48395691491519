/* const url = `http://localhost:4000/api/v1`
const userBaseUrl = `http://localhost:4000/api/v1/users` */
const API =
    process.env.NODE_ENV === "production"
    ? "http://104.237.151.150:4000/api/v1"
    : "http://localhost:4000/api/v1";

class DataModel {
  // access all data from API
  // static allData = () => {
  //   // api index route
  //   return fetch(`${API}/records`, {
  //     method: "GET",
  //     headers: {
  //       authorization: `Bearer ${localStorage.token}`,
  //     },
  //   }).then((res) => res.json());
  // };

  static deleteRow = (rowId) => {
    return fetch(`${API}/records/${rowId}`, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
      },
    }).then((res) => res.json())
      .then((res) => console.log(res));
  }

  static addRow = (newData) => {
    return fetch(`${API}/records/create`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newData)
      
    }).then((res) => res.json())
      .then((res) => console.log(res));
  }

  static updateRow = (newData, rowId) => {
    return fetch(`${API}/records/${rowId}`, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newData)
      
    }).then((res) => res.json())
      .then((res) => console.log(res));
  }

  static updateExecRow = (newData, rowId) => {
    return fetch(`${API}/records/exec/${rowId}`, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newData)
      
    }).then((res) => res.json())
      .then((res) => console.log(res));
  }


  static import = (newData) => {
    return fetch(`${API}/records/import_bulk`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(newData)
      
    }).then((res) => res.json())
      .then((res) => console.log(res));
  }

  static zipData = () => {
    // api index route
    return fetch(`${API}/records/zip`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.token}`,
      },
    }).then((res) => res.json())
      .then((res) => console.log(res.returnArr));
  };
}


export default DataModel;