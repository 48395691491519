import { atom } from "recoil";


export const tokenAtom = atom({
    key: "tokenState",
    default: [],
  });
  
  export const userState = atom({
    key: "userState",
    default: null,
  });
  
  export const userIdState = atom({
    key: "userId",
    default: null,
  });
  
  export const userNameState = atom({
    key: "userName",
    default: null,
  });