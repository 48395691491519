import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom';
import AuthModel from "../../models/auth";
import { tokenAtom } from "../../recoil/atoms"
import "./Logout.css"

const Logout = () => {
	const [_, setToken] = useRecoilState(tokenAtom);
    const navigate = useNavigate();


    const logout = () => {
        AuthModel.deauthenticateUser();
		setToken(null);
        console.log(localStorage)
        navigate('/');
        
    }

    return (
			<div className='LogoutButton' onClick={logout}>
		
					<b>Logout</b>
			
			</div>
		);
};

export default Logout;