import React, { useState } from "react";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Copyright from './Copyright'
import Title from './Title'

import { tokenAtom, userState } from "../recoil/atoms"
import { useSetRecoilState, useRecoilState } from "recoil";
import AuthModel from "../models/auth";
import { useNavigate } from 'react-router-dom';
import "./LandingPage.css";


const theme = createTheme();

export default function Login() {
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const setUser = useSetRecoilState(userState);
  const [_, setToken] = useRecoilState(tokenAtom);
  const navigate = useNavigate();
  
  function handleSubmit(event) {
    
    event.preventDefault();
    AuthModel.login({ email, password }).then(json => {
      if (json.signedJwt){
        AuthModel.authenticateUser(json.signedJwt)
        AuthModel.verify().then(setUser(json.user))
        setToken(json.signedJwt);
        navigate('/dashboard');
      } else {
        setErrorMessage('Incorrect Username or Password');
      }
    });
    }

  return (
    <ThemeProvider theme={theme}>
      <Title />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
       
          </Avatar> */}
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              defaultValue={email}
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              defaultValue={password}
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Typography component="h1" variant="h5">
              {errorMessage && <p className="login_error"> {errorMessage} </p>}
            </Typography>

            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}


